// extracted by mini-css-extract-plugin
export var card = "demo-module--card--bd612";
export var check = "demo-module--check--da477";
export var checklist = "demo-module--checklist--dd4a0";
export var content = "demo-module--content--ce495";
export var demo = "demo-module--demo--85040";
export var form = "demo-module--form--4dfe3";
export var img = "demo-module--img--046d2";
export var label = "demo-module--label--0e8ba";
export var metrics = "demo-module--metrics--f04aa";
export var number = "demo-module--number--5daf0";